<template>
  <div class="dialog-setting">
    <div class="dialog-scroll">
      <div class="dialog-box">
        <div class="dialog-header">
          <h2 class="r-title-2">
            <slot name="title"></slot>
          </h2>
          <div class="close-icon" @click="closeDialog">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 19L19 5M5 5L19 19" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <slot></slot>
        <div v-if="showBtns == true" class="dialog-btns">
          <div class="mr">
            <Button @click="submitDialog" :mode="firstBtnMode">{{ firstBtnText }}</Button>
          </div>
          <div class="mr" v-if="showThirdBtn">
            <Button @click="submitDialog_2" :mode="firstBtnMode">{{ thirdBtnText }}</Button>
          </div>
          <Button v-if="showCancelBtn" @click="cancelDialog" mode="grey">{{ secondBtnText }}</Button>
          <slot name="checkbox-confirm"></slot>
          <slot name="remove-btn"></slot>
          <slot name="select"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/parts/button.vue";

export default {
  components: {
    Button
  },
  data() {
    return {
      modalStatus: ""
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showBtns: {
      type: Boolean,
      default: true
    },
    showCancelBtn: {
      type: Boolean,
      default: true
    },
    showThirdBtn: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    firstBtnMode: {
      type: String,
      default: "blue"
    },
    firstBtnText: {
      type: String,
      default: "Submit"
    },
    thirdBtnText: {
      type: String
    },
    secondBtnText: {
      type: String
    }
  },
  methods: {
    submitDialog() {
      this.$emit("submitDialog");
    },
    submitDialog_2() {
      this.$emit("submitDialog_2");
    },
    cancelDialog() {
      document.querySelector("body").style.overflow = null;
      this.$emit("cancelDialog");
    },
    closeDialog() {
      document.querySelector("body").style.overflow = null;
      this.$emit("cancelDialog");
      this.$emit("update:show", false);
      this.$store.commit("Set_Choose_Employee", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-setting {
  color: var(--dark-100);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--dark-40);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;

  &.firstModal {
    .close-icon,
    .dialog-btns > button {
      display: none;
    }
  }
}
.dialog-scroll {
  padding: 20px 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  touch-action: none;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.dialog-box {
  margin: auto;
  width: 100%;
  max-height: 100%;
  max-width: 710px;
  padding: 24px 20px 20px;
  border-radius: 10px;
  background: var(--white-100);
  overflow-y: auto;
}
.dialog-header {
  display: flex;
  padding-bottom: 6px;

  .r-title-2 {
    padding-right: 20px;
  }
  svg {
    height: 24px;
    width: 24px;
    min-width: 24px;
    color: var(--dark-40);
  }
}
.dialog-btns {
  display: flex;
  margin-top: 20px;
}
.mr {
  margin-right: 20px;
  flex-shrink: 0;
}
span.blue {
  color: var(--blue-main);
  cursor: pointer;
}
.close-icon {
  padding: 10px;
  cursor: pointer;
  margin-left: auto;
  width: 44px;
  height: 44px;
  margin-top: 7px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms ease;
  @media (max-width: 764px) {
    margin-top: 0;
  }

  &:hover {
    background: #f5f8fa;
  }
}
@media (max-width: 375px) {
  .dialog-btns {
    flex-direction: column;
    *:not(:first-child) {
      margin-top: 20px;
    }
  }
  .mr {
    margin-right: 0;
    button {
      width: 100%;
    }
  }
}
body.ios .choose-employee .dialog-scroll {
  padding-bottom: 0;
}
@media (max-width: 767px) {
  .choose-employee .dialog-scroll {
    padding: 72px 0 0;

    .dialog-box {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>
