<template>
  <div class="search-dish s-text-caption">
    <label class="label-search">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="7.50935" cy="7.54402" rx="4.21053" ry="4.21053" stroke="#3CAFE9" stroke-width="1.4" stroke-linecap="round" />
        <path d="M10.4043 10.7017L13.0359 13.3332" stroke="#3CAFE9" stroke-width="1.4" stroke-linecap="round" />
      </svg>
      <input :placeholder="placeholder" class="s-text-caption" type="text" :value="value" @input="subInp" />
    </label>
    <div class="svg-wrapper" @click="searchClear">
      <svg @click="$emit('update:value')" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33398 3.3335L12.6673 12.6668M12.6673 3.3335L3.33398 12.6668" stroke="#3CAFE9" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String
      // default: "Поиск блюд"
    }
  },
  methods: {
    subInp(e) {
      this.$emit("update:value", e.target.value);
      this.$emit("inpEvent");
    },
    searchClear() {
      this.$emit("searchClear");
    }
  }
};
</script>

<style lang="scss" scoped>
.search-dish {
  position: relative;
  transition: 0.5s;
  width: 130px;
  display: flex;
  height: 48px;
  align-items: center;
  border-radius: 8px;
  background: var(--blue-light);
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  &:focus-within {
    width: 300px;

    @media (max-width: 764px) {
        width: 300px;
        max-width: calc(100% - 56px);
        flex-shrink: 0;
    }
  }
}
.label-search svg {
  margin-top: -2px;
  margin-right: 8px;
  position: relative;
  left: 15px;
}
.svg-wrapper {
  opacity: 0;
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  position: absolute;
  right: 16px;
  visibility: hidden;
}
.search-dish:focus-within .svg-wrapper {
  visibility: visible;
  transition-delay: 0.3s;
  opacity: 1;
}
.label-search {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
input {
  padding: 0 12px 0 16px;
  width: 100%;
  height: 100%;
  color: var(--dark-100);
  text-transform: uppercase;
  background: var(--blue-light);
  border: none;
  outline: none;

  @media (max-width: 767px) {
    font-size: 16px;
  }

  &::placeholder {
    transition: 0.5s;
    color: var(--dark-100);
  }
  &:focus {
    &::placeholder {
      color: var(--dark-40);
    }
  }
}
</style>
