<template>
  <div class="choose-employee">
    <Dialog v-if="visib" v-model:show="visib" :showBtns="false">
      <div class="choose-employee"></div>
      <template v-slot:title>{{ DISHES_TRANSLATE?.modals?.orders?.edit?.title }}</template>
      <SearchDish v-model:value="search" :placeholder="DISHES_TRANSLATE?.modals?.orders?.edit?.search"></SearchDish>
      <div class="employee-list-outer">
        <div class="employee-list">
          <div v-for="(user, index) in searchRes" :key="index" class="employee-cell s-text-alert">
            <div class="employee-name guest" @click="pickEmployee(user)">
              <input type="checkbox" :checked="selectUserId == user?.id" />
              <svg v-if="selectUserId == user?.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.0643 18L20.4016 6M8.97718 17.9996L3.60156 12.2635" stroke="currentColor" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <svg v-if="user?.is_guest" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 8H12.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 12H12V16H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <InfoMessage v-if="user?.is_guest" bottom="35px" left="-23px" arrowDir="bl">Бюджет для заказа обедов для гостей, которые приходят в офис на встречу или мероприятие.</InfoMessage>
              {{ user?.name }} <template v-if="!user?.is_guest">{{ user?.last_name }}</template>
            </div>
            <span v-if="!user?.limit" class="employee-limit">{{ DISHES_TRANSLATE?.modals?.orders?.edit?.limit }} 0 ₴</span>
            <span v-else class="employee-limit">{{ DISHES_TRANSLATE?.modals?.orders?.edit?.limit }} {{ user?.limit?.sum }} ₴</span>
            <div class="days-list">
              <div v-for="day in orders" :key="day.date" class="day" :class="{ active: checkDay(day, user) }">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.0643 18L20.4016 6M8.97718 17.9996L3.60156 12.2635" stroke="currentColor" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {{ day?.short_name }}
              </div>
            </div>
          </div>
          <div v-if="searchRes.length == 0">
            <div class="employee-cell cell-empty s-text-alert">
              {{ DISHES_TRANSLATE?.modals?.orders?.edit?.empty_orders?.label }}&nbsp;<span class="gray">{{ DISHES_TRANSLATE?.modals?.orders?.edit?.empty_orders?.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/dialogs/dialog-submit-setting.vue";
import InfoMessage from "@/components/parts/info-message";
import SearchDish from "@/components/parts/search-dish";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      visib: this.show,
      search: ""
    };
  },
  computed: {
    ...mapGetters(["GET_EMPLOYEES", "USER", "ORDERS", "SELECT_USER", "DISHES_TRANSLATE"]),
    searchRes() {
      if (!this.search) {
        return this.GET_EMPLOYEES;
      } else {
        let newArr = this.GET_EMPLOYEES.filter(item => `${item.name.toLowerCase()}${item.last_name.toLowerCase()}`.includes(this.search.toLowerCase()));
        return newArr;
      }
    },
    orders() {
      let clon = JSON.parse(JSON.stringify(this.ORDERS));

      if (null === clon) {
        return clon;
      }

      const orders = Object.values(clon)?.filter((order) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        const date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        const currentDate = `${year}-${month}-${date}`;

        return order?.date !== currentDate;
      });

      return orders;
    },
    selectUserId() {
      return this.SELECT_USER || this.USER?.id;
    }
  },
  methods: {
    ...mapActions(["SelectUser", "GetOrders", "GetAllDishes"]),
    checkDay(day, user) {
      let x = false;
      if (user?.orders?.length > 0) {
        user?.orders.map(e => {
          if (e.date == day.date) x = true;
        });
      }
      return x;
    },
    pickEmployee(user) {
      this.$router.push({ name: "Dishes" });
      this.SelectUser(user);
      this.GetOrders();
      this.GetAllDishes("?isGroup=1");
      this.visib = false;
      document.querySelector("body").style.overflow = null;
      this.$store.commit("Set_Choose_Employee", false);
      this.$store.commit("SetSurchargeChecker", true);
    }
  },
  watch: {
    visib() {
      this.$emit("update:show", this.visib);
    },
    show() {
      this.visib = this.show;
    }
  },
  components: {
    Dialog,
    InfoMessage,
    SearchDish
  }
};
</script>

<style lang="scss">
.choose-employee {
  input[type="checkbox"] {
    display: none;
  }

  .gray {
    color: var(--dark-40);
  }

  input[type="checkbox"]:checked ~ svg {
    display: flex;
  }
  .dialog-box {
    padding: 21px 20px 20px;
    max-height: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .dialog-header {
    padding-bottom: 16px;

    @media (max-width: 767px) {
      padding-bottom: 13px;
    }
  }
  .close-icon {
    position: relative;
    left: 10px;
    bottom: 6px;

    @media (max-width: 767px) {
      left: 9px;
      bottom: 10px;
    }
  }
  .search-dish {
    width: 100% !important;
    max-width: none !important;
    svg {
      visibility: visible;
      opacity: 1;
    }
    .label-search {
      width: 100%;
      padding-right: 30px;
    }
  }
  .days-list,
  .employee-cell {
    display: flex;
    align-items: center;
  }
  .employee-cell {
    margin: 20px 0 25px;

    @media (max-width: 767px) {
      margin: 20px 0 47px;
    }

    &.cell-empty {
      display: block;
    }
  }
  .employee-name {
    user-select: none;
    display: flex;
    align-items: center;
    width: 230px;
    cursor: pointer;
    &:hover {
      color: var(--blue-main);
    }
    svg {
      display: none;
      margin-top: -3px;
      margin-right: 4px;
      color: var(--blue-main);
      width: 16px;
      height: 16px;
    }
  }
  .employee-name.guest {
    position: relative;
    svg {
      display: flex;
    }
    &:hover .info-meassage {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }
  .days-list {
    margin-left: auto;
  }
  .day {
    cursor: pointer;
    user-select: none;
    height: 48px;
    width: 54px;
    margin: 0 1px;
    border-radius: 8px;
    background: var(--bg-main);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      margin-top: -3px;
      margin-right: 2px;
      color: var(--white-100);
      display: none;
    }
    &.active {
      color: var(--white-100);
      background: var(--blue-main);
      svg {
        display: flex;
      }
    }
  }
  .employee-list {
    margin: 10px -20px 0;
    padding: 0 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    &-outer {
      position: relative;
      height: calc(100% - 156px);
      &:before {
        content: "";
        width: calc(100% + 40px);
        position: absolute;
        height: 33px;
        left: -20px;
        top: -33px;
        box-shadow: 0 10px 14px rgba(0, 0, 0, 0.05);

        @media (max-width: 764px) {
          top: -50px;
        }
      }
    }

    @media (max-width: 767px) {
      margin: 33px -20px 0;
    }
  }
  @media (max-width: 764px) {
    .days-list {
      display: none;
    }
    .employee-limit {
      margin-left: auto;
      white-space: nowrap;
    }
  }
}
</style>
